import React,{ useEffect, useState } from "react";
import InputText1 from "../components/InputText1";
import { adminServices } from "../services/AdminServices";
import Button2 from "../components/Button2";

const SettingsPage = ()=>{
    const [data, setData] = useState([]);
    const [padalaLimit, setPadalaLimit] = useState('');
    const [percentRate, setPercentRate] = useState('');
    const [serviceFee, setServiceFee] = useState('');
    const [posFee, setPosFee] = useState('');
    const [bucorFee, setBucorFee] = useState('');
    const [status, setStatus] = useState('');
    
    useEffect(()=>{
        getConfig();
    },[]);

    setTimeout(() => {
        window.location.href="/settings";
    }, 60000);

    const getConfig = async () => {
        var form = new FormData();
        form.append("params", '');
        try {
            const response = await adminServices.storedProcedure("get_settings", form);
            const settingsData = response.data;
            setData(settingsData);
            setPadalaLimit(settingsData[0].padala_limit_per_month);
            setPercentRate(settingsData[0].percent_rate + '%');
            setServiceFee(settingsData[0].service_fee + '%');
            setPosFee(settingsData[0].pos_fee + '%');
            setBucorFee(settingsData[0].bucor_rate + '%');
            setStatus(settingsData[0].status);
        } catch (e) {
            console.log(e);
            if (e.response.status === 401) {
                window.location.href = "/";
            }
        }
    };


    return (<div>
        <h3>System Settings</h3>
       <form className="padalaForm">
             
            <InputText1 title={"Padala Amount Limit"}
            value={padalaLimit}
            placeholder={"0.00"}
            onChange={(e)=>{
                setPadalaLimit(e.target.value);
            }}
            >
            </InputText1>

            <InputText1 title={"Interest Rate%"}
             placeholder={"0%"}
             value={percentRate}
             onChange={(e)=>{ setPercentRate(e.target.value.replace('%','') + "%")}}
             >
            </InputText1>

            <InputText1 title={"Service Fee%"} placeholder={"0%"}
            value={serviceFee}
            onChange={(e)=>{ setServiceFee(e.target.value.replace('%','') + '%') }}>;
            </InputText1>

            <InputText1 title={"POS Fee%"} placeholder={"0%"}
                value={posFee}
                onChange={(e)=>{ setPosFee(e.target.value.replace('%','') + '%') }}
            >
            
            </InputText1>

            <InputText1 title={"Bucor Fee%"} placeholder={"0%"}
            value={bucorFee}
            onChange={(e)=>{ setBucorFee(e.target.value.replace('%','') + "%") }}>
            </InputText1>

            <InputText1 title={"Status"} placeholder={""}
            value={status}
            onChange={(e)=>{ setStatus(e.target.value.replace('%','')   ) }}
            readonly={true}>
            </InputText1>

       </form>
    
       {status!="Pending" && <Button2
                text={"Submit"}
                onClick={async ()=>{
                    
                    const form = new FormData();
                    form.append("padala_limit_per_month", padalaLimit);
                    form.append("percent_rate", percentRate.replace('%',''));
                    form.append("bucor_rate", bucorFee.replace('%',''));
                    form.append("service_fee", serviceFee.replace('%',''));
                    form.append("pos_fee", posFee.replace('%',''));

                    await adminServices.submitConfig(form).then((r)=>{
                        if(r.status ==204){
                            window.location.href = "/settings";
                        }
                    }).catch((e)=>{
                        alert("Error occured");
                    })
                    
                }}
                style={{ width: "200px", backgroundColor: "#61dd48" }}
              />}


    </div>)
}

export default SettingsPage;
