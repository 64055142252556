import axios, {AxiosResponse} from "axios";
let baseURL = process.env.REACT_APP_BASE_URL;

export const adminServices = {
    getToken,
    sendPadala,
    serviceCategories,
    registerMerchant,
    registerService,
    login,
    verify,
    storedProcedure,
    sendMerchantPadala,
    updatePDL,
    addClientTransaction,
    updateClientTransaction,
    updatePadala,
    submitConfig,
    addRFQ,
    addRFQDetails,
    addKiosk,
    updateKiosk,
    emailRFQ,
    addPo,
    addReceiving,
    addReceivingItems
}

async function getToken(){
    const config = {
        headers:{
            Authorization : 'Basic anVhbjpwYWJsbw==',
        }
    }

    return await axios.get(baseURL + "token",config);
}

async function serviceCategories(){
    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }

    return await axios.get(`${baseURL}servicecategories`,config);
}

async function sendPadala(token, data){
    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }

    return await axios.post(`${baseURL}padala`,data,config);
}

async function sendMerchantPadala(token, data){
    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }

    return await axios.post(`${baseURL}MerchantPadala`,data,config);
}

async function registerMerchant(token, data){
    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }

    return await axios.post(`${baseURL}merchants`,data,config);
}

async function registerService(token, data){
    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + token,
        }
    }

    return await axios.post(`${baseURL}services`,data,config);
}

async function login(emailAddr, passwd){
    var token = process.env.REACT_APP_LOGIN_TOKEN

    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + token,
        }
    }

    var data = {EmailAddress: emailAddr, Password: passwd}
    
    return await axios.post(`${baseURL}login/signin`,data,config);
}

async function verify(pin){
    var token = process.env.REACT_APP_LOGIN_TOKEN

    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + token,
        }
    }

    var data = {otpCode: pin, secretKey: localStorage.getItem("secretKey"),email_Address: localStorage.getItem("emailAddr")}
    
    return await axios.post(`${baseURL}otp/verify`,data,config);
}

async function updatePDL(data){
    const config = {
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.put(`${baseURL}clients/` + data.id,data,config);
}

async function updateClientTransaction(data){

    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.put(`${baseURL}clientTransaction/` + data.id,data,config);
}

async function updatePadala(data){

    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.put(`${baseURL}padala/` + data.id,data,config);
}

async function addClientTransaction(data){
    const config = {
        headers:{
            'content-type':'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }

    return await axios.post(`${baseURL}clientTransaction`,data,config);
}

async function submitConfig(data){
    const config = {
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }

    return await axios.post(`${baseURL}configs`,data,config);
}


async function storedProcedure(sp,data){
    const config = {
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.post(`${baseURL}sp/` + sp,data,config);
}

async function addRFQ(data){
    const config = {
        headers:{
            'Content-Type': 'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.post(`${baseURL}rfq`,data,config);
}

async function addRFQDetails(data){
    const config = {
        headers:{
            'Content-Type': 'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.post(`${baseURL}rfqdetails`,data,config);
}

async function addKiosk(data){
    const config = {
        headers:{
            'Content-Type': 'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.post(`${baseURL}kiosks`,data,config);
}

async function updateKiosk(data){
    const config = {
        headers:{
            'Content-Type': 'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.put(`${baseURL}kiosks/` + data.kioskId + data.kioskId,data,config);
}

async function emailRFQ(id){
    const config = {
        headers:{
            'Content-Type': 'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.post(`${baseURL}rfq/send-email/` + id,{},config);
}

async function addPo(data){
    const config = {
        headers:{
            'Content-Type': 'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.post(`${baseURL}productorder`,data,config);
}

async function addReceiving(data){
    const config = {
        headers:{
            'Content-Type': 'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.post(`${baseURL}receiving`,data,config);
}

async function addReceivingItems(data){
    const config = {
        headers:{
            'Content-Type': 'application/json',
            Authorization : 'Bearer ' + localStorage.getItem('appToken'),
        }
    }
    
    return await axios.post(`${baseURL}receivingdetails`,data,config);
}