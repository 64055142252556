import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { walletServices } from "../services/WalletService";

const PDLManagementPage = () => {
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  setTimeout(()=>{
    window.location.href='/pdl-management';
  },60000)

  useEffect(()=>{
      getPDLProfiles();
  },[])

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter data based on search query
    const filteredRows = data.filter((row) =>
      row.pdlNumber.toLowerCase().includes(query) ||
      row.firstName.toLowerCase().includes(query) ||
      row.lastName.toLowerCase().includes(query)
    );
    setFilteredData(filteredRows);
  };

    


  const getPDLProfiles = ()=>{
      walletServices.pdlList().then((r)=>{
          setData(r.data);
          setFilteredData(r.data);
          var _col = [
              { name: 'ID', selector: data=>data.id, sortable: true },
              { name: 'PDL Number', selector: data=>data.pdlNumber, sortable: true },
              { name: 'First Name', selector: data=>data.firstName, sortable: true },
              { name: 'Middle Name', selector: data=>data.middleName, sortable: true },
              { name: 'Last Name', selector: data=>data.lastName, sortable: true },
              { name: 'Gender', selector: data=>data.gender, sortable: true },
              { name: 'Facility Number', selector: data=>data.facilityNumber, sortable: true },
              { name: 'Wallet', selector: data=>"Php "+ data.walletAmount.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }), sortable: true },
          ]
          setColumns(_col);
      }).catch((r)=>{
          if(r.response.status === 401){
              window.location.href="/"
          }
      });
  }

  const handleSelectedRow = (row)=>{
    console.log(row);
    localStorage.setItem('selectedPDL',btoa(JSON.stringify(row)));
    window.location.href = "/pdl-transactions";
  }


  return <div>
    <div className="sc-fPXMhL QAQQD" style={{fontSize:20, fontWeight:'bold', marginBottom:10}}>PDL Profiles</div>
      <div>
          <input type="search" placeholder="Search" style={{
              height:35,
              width: '50%',
              paddingLeft: 10
          }}
          onChange={handleSearchChange}
          value={searchQuery}
          ></input>
      </div>
      <br/>
      <DataTable 
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          onRowClicked={handleSelectedRow}
      >

      </DataTable>
  </div>;
};

export default PDLManagementPage;
